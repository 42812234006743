import React, { useState } from 'react'
import clsx from 'clsx'
import { ResultsList } from '../../organism/ResultsList/ResultsList'
import styles from './Results.module.scss'
import { ResultDetail } from '../../organism/ResultDetail/ResultDetail'
import { ResultEval } from '../../organism/ResultEval/ResultEval'
import { ConfigProps, StationObjProps } from '../../page/Map'
import { Updater } from 'use-immer'

interface ResultsProps {
    className?: string
    config: ConfigProps
    updateConfig: Updater<ConfigProps>
    stationsObj: StationObjProps
    updateStationsObj: Updater<StationObjProps>
    map?: google.maps.Map
}

export const Results = ({
    className,
    config,
    updateConfig,
    stationsObj,
    updateStationsObj,
    map,
}: ResultsProps) => {
    const [stationEval, setStationEval] = useState<null | string>(null)

    const setActiveStation = (station: StationObjProps['activeStation']) => {
        updateStationsObj((draft) => {
            draft.activeStation = station
        })
    }

    return (
        <div className={clsx(styles.wrapper, className)}>
            <ResultsList
                setActiveStation={setActiveStation}
                stations={stationsObj.stations}
                config={config}
                updateConfig={updateConfig}
            />
            {stationsObj.activeStation && config.type && (
                <ResultDetail
                    setStation={setActiveStation}
                    setStationEval={setStationEval}
                    station={stationsObj.activeStation}
                    mapType={config.type}
                    map={map}
                    updateConfig={updateConfig}
                />
            )}
            {stationEval && config.type && (
                <ResultEval
                    stationEval={stationEval}
                    setStationEval={setStationEval}
                    station={stationsObj.activeStation}
                    mapType={config.type}
                />
            )}
        </div>
    )
}
